<template>
    <MenuButton 
        :title="$t('text-burger-menu')" 
        variant="outline"
        :on-click="handleClick"
    >
        <NavbarIcon class="w-5 h-5" />
    </MenuButton>
</template>

<script setup>
import MenuButton from "@components/ui/menu-button";
import NavbarIcon from "@components/icons/navbar-icon";

const { $eventBus } = useNuxtApp();

const handleClick = () => { 
    $eventBus.emit('sidebar:open', 'MAIN_MENU_VIEW');
};

</script>
