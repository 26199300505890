<template>
    <MenuButton
        :title="$t('nav-menu-inspiration')"
        :variant="routeName.startsWith('inspiration___') || routeName.startsWith('inspiration-type-slug___') ? 'accent' : 'outline'"
        :on-click="handleClick"
    >
        <IdeaIcon class="w-6 h-6" />
    </MenuButton>
</template>

<script setup>
import { ROUTES } from "@utils/routes";
import MenuButton from "@components/ui/menu-button";
import IdeaIcon from "@components/icons/idea-icon";

const route = useRoute();

const routeName = computed(() => route?.name || '');

const handleClick = async () => {
    await navigatorTo(translatePath(ROUTES.CODE.INSPIRATION));
};

</script>
