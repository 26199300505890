<template>
    <MenuButton 
        variant="outline" 
        size="custom" 
        class-name="w-fit h-10 px-2"
        :on-click="handleJoin"
    >
        {{ $t("join-button") }}
    </MenuButton>
</template>

<script setup>
import { useSelectedStore } from '@stores/selected';
import MenuButton from "@components/ui/menu-button";

const { $eventBus } = useNuxtApp();

const store = useSelectedStore();

const handleJoin = () => { 
    store.setAction(null);
    $eventBus.emit('modal:open', 'LOGIN_VIEW');
};

</script>
