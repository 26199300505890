<template>
    <div class="visible lg:hidden h-15">
      <nav class="h-15 w-full py-1.5 px-5 flex items-center justify-between fixed start-0 bottom-0 z-10 bg-menu-dark shadow-400">
        <Link
          class="flex p-2 h-full items-center justify-center text-light focus:outline-none focus:text-accent-dark hover:text-accent-dark-hover"
          :aria-label="$t('text-home')"
          :href="translatePath(ROUTES.CODE.HOME)"
        >
          <span class="sr-only">{{ $t('text-home') }}</span>
          <HomeIcon class="w-5 h-5" />
        </Link>

        <button
          v-if="search === true"
          class="flex p-2 h-full items-center justify-center text-light focus:outline-none focus:text-accent-dark hover:text-accent-dark-hover"
          :aria-label="$t('text-search')"
          @click="ui.toggleMobileSearch"
        >
          <span class="sr-only">{{ $t('text-search') }}</span>
          <SearchIcon class="w-5 h-5" />
        </button>

        <InspirationButton variant="simple" />

        <NewsMenuButton variant="simple" />

        <button
          v-if="auth.isLoggedIn"
          class="flex p-2 h-full items-center justify-center text-light focus:outline-none focus:text-accent-dark hover:text-accent-dark-hover"
          :aria-label="$t('text-user')"
          @click="() => handleSidebar('AUTH_MENU_VIEW')"
        >
          <span class="sr-only">{{ $t('text-user') }}</span>
          <UserIcon class="w-6 h-6" />
        </button>
        <button
          v-else
          class="flex p-2 h-full items-center justify-center text-light focus:outline-none focus:text-accent-dark hover:text-accent-dark-hover"
          :aria-label="$t('text-user')"
          @click="handleAuthModal"
        >
          <span class="sr-only">{{ $t('text-user') }}</span>
          <UserIcon class="w-5 h-5" />
        </button>
      </nav>
    </div>
</template>

<script setup>
import { ROUTES } from "@utils/routes";
import { useAuthStore } from '@stores/auth';
import { useUIStore } from '@stores/ui';
import SearchIcon from "@components/icons/search-icon";
import HomeIcon from "@components/icons/home-icon";
import UserIcon from "@components/icons/user-icon";
import InspirationButton from "@components/layout/navbar/inspiration-button";
import NewsMenuButton from "@components/layout/navbar/news-menu-button";
import Link from "@components/ui/link/link";

defineProps({
    search: {
        type: Boolean,
        default: true
    }
});

const { $eventBus } = useNuxtApp();
const auth = useAuthStore();
const ui = useUIStore();

const handleSidebar = (view) => {
    $eventBus.emit('sidebar:open', view);
}

const openModal = (view) => {
    $eventBus.emit('modal:open', view);
}

const handleAuthModal = () => {
    openModal('LOGIN_VIEW');
}

</script>
