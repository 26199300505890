<template>
  <div v-if="show" class="w-full p-4 fixed start-0 bottom-15 sm:bottom-0 z-10 bg-primary-dark text-light border-t border-solid border-light">
    <button
      class="inline-block outline-none focus:outline-none absolute end-3 top-3 w-4 h-4"
      :aria-label="$t('text-close')"
      @click="handleClose"
    >
      <span class="sr-only">{{ $t("text-close") }}</span>
      <CloseIcon class="w-4 h-4" />
    </button>
    <div v-if="showConfigure" class="text-center sm:text-left mr-5 sm:mr-0 mb-2 text-base">
      {{ $t('text-cookie-consent-popup') }}
    </div>
    <div class="w-full flex flex-col sm:flex-row items-center justify-center sm:justify-between">
      <div class="w-full flex flex-col">
        <div v-if="!showConfigure" class="text-center sm:text-left mr-5 sm:mr-0 mb-2 text-base pt-2">
          {{ $t('text-cookie-consent-popup') }}
        </div>
        <div v-if="showConfigure" class="flex flex-row items-center justify-start">
          <Toggle
            v-model="autoriseDefaultCookie"
            name="autoriseDefault"
            variant="navbar"
            :disabled="true"
            class="flex-shrink-0 flex-grow-0"
          />
          <div class="text-sm">
            {{ $t('text-cookie-consent-authorization-default') }}
          </div>
        </div>
        <div v-if="showConfigure" class="flex flex-row items-center justify-start">
          <Toggle
            v-model="cookieConsentCookie"
            name="cookieConsent"
            variant="navbar"
          />
          <div class="text-sm">
            {{ $t('text-cookie-consent-authorization-analytics') }}
          </div>
        </div>
        <div v-if="showConfigure" class="flex flex-row items-center justify-start">
          <Toggle
            v-model="targetedAdvertisingCookie"
            name="targetedAdvertising"
            variant="navbar"
          />
          <div class="text-sm">
            {{ $t('text-cookie-consent-authorization-advertising') }}
          </div>
        </div>
      </div>
      <div :class="[ 'w-full sm:w-fit ml-5 sm:ml-0 flex flex-col items-center justify-center sm:justify-between', showConfigure ? '' : 'sm:flex-row' ]">
        <Button
          size="small"
          variant="navbar"
          :on-click="handleAccept"
          class="mt-2 mr-5 w-full sm:w-fit whitespace-nowrap"
        >
          {{ $t('text-cookie-consent-button-accept') }}
        </Button>
        <Button
          v-if="!showConfigure"
          size="small"
          variant="navbar"
          :on-click="handleConfigure"
          class="mt-2 mr-5 w-full sm:w-fit whitespace-nowrap"
        >
          {{ $t('text-cookie-consent-button-configure') }}
        </Button>
        <Button
          v-if="showConfigure"
          size="small"
          variant="navbar"
          :on-click="handleSave"
          class="mt-2 mr-5 w-full sm:w-fit whitespace-nowrap"
        >
          {{ $t('text-cookie-consent-button-save') }}
        </Button>
        <Button
          size="small"
          variant="navbar"
          :on-click="handleReject"
          class="mt-2 mr-5 w-full sm:w-fit whitespace-nowrap"
        >
          {{ $t('text-cookie-consent-button-reject') }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup>
import _ from 'lodash-es';
import Cookies from 'js-cookie';
import Button from "@components/ui/button";
import CloseIcon from '@components/icons/close-icon'
import Toggle from "@components/ui/toggle";

const gtm = useGtm();
const date = new Date(Date.now() + 31536000000);
const rejectDate = new Date(Date.now() + 604800000);

const autoriseDefaultCookie = useCookie('autoriseDefault', { default: () => true, path: '/', expires: date });
const cookieConsentCookie = useCookie('cookieConsent', { default: () => false, path: '/', expires: date });
const targetedAdvertisingCookie = useCookie('targetedAdvertising', { default: () => false, path: '/', expires: date });
const popupConsentCookie = ref(Cookies.get('popupConsent') === 'true');

const show = ref(false);
const showConfigure = ref(false);

const checkCookieConsent = () => {
  if (autoriseDefaultCookie.value) {
      gtm.dataLayer().push({
        'event': 'consent',
        'consent_type': 'default',
        'functionality_storage': 'granted',
        'security_storage': 'granted'
      });
  } else {
      gtm.dataLayer().push({
        'event': 'consent',
        'consent_type': 'default',
        'functionality_storage': 'denied',
        'security_storage': 'denied'
      });
  }
  if (cookieConsentCookie.value) {
      gtm.dataLayer().push({
        'event': 'consent',
        'consent_type': 'default',
        'personalization_storage': 'granted',
        'analytics_storage': 'granted'
      });
  } else {
      gtm.dataLayer().push({
        'event': 'consent',
        'consent_type': 'default',
        'personalization_storage': 'denied',
        'analytics_storage': 'denied'
      });
  }
  if (targetedAdvertisingCookie.value) {
      gtm.dataLayer().push({
        'event': 'consent',
        'consent_type': 'default',
        'ad_storage': 'granted'
      });
  } else {
      gtm.dataLayer().push({
        'event': 'consent',
        'consent_type': 'default',
        'ad_storage': 'denied'
      });
  }
}

const handleClose = () => {
  show.value = false;
}

const handleAccept = () => {
  Cookies.set('popupConsent', 'true', { path: '/', expires: date });
  autoriseDefaultCookie.value = true;
  cookieConsentCookie.value = true;
  targetedAdvertisingCookie.value = true;
  checkCookieConsent();
  handleClose();
}

const handleConfigure = () => {
  showConfigure.value = true;
}

const handleSave = () => {
  Cookies.set('popupConsent', 'true', { path: '/', expires: date });
  checkCookieConsent();
  handleClose();
}

const handleReject = () => {
  Cookies.set('popupConsent', 'true', { path: '/', expires: rejectDate });
  autoriseDefaultCookie.value = true;
  cookieConsentCookie.value = false;
  targetedAdvertisingCookie.value = false;
  checkCookieConsent();
  handleClose();
}

onMounted(() => {
  show.value = !popupConsentCookie.value;
});

</script>
